<template>
  <FeathersVuexFind
    service="scenarios"
    :params="params"
    :fetchQuery="fetchQuery"
    watch="fetchQuery"
  >
    <template #default="{ items, isFindPending }">
      <InputSelect
        label="Scenario"
        v-model="scenario_id"
        :search-input.sync="searchInput"
        :items="items"
        item-text="name"
        item-value="id"
        :loading="isFindPending"
        v-bind="$attrs"
        :create-rules="createRules"
        :rename-rules="renameRules"
        @create="create"
        @rename="rename"
        hide-save-as
      ></InputSelect>
    </template>
  </FeathersVuexFind>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { FeathersVuexFind } from "feathers-vuex";

export default {
  name: "SelectScenario",
  props: {
    value: {
      type: Number,
      required: false,
      default: null,
    },
  },
  components: {
    InputSelect: () => import("@/components/InputSelect"),
    FeathersVuexFind,
  },
  data() {
    return {
      searchInput: null,
      createRules: [(value) => !/[/\\:*?<>|"']/g.test(value)],
      renameRules: [(value) => !/[/\\:*?<>|"']/g.test(value)],
      select: ["id", "name"],
    };
  },
  computed: {
    ...mapState({}),
    ...mapGetters({}),
    scenario_id: {
      get() {
        return this.value;
      },
      set(id) {
        this.$emit("input", id);
      },
    },
    fetchQuery() {
      return {
        name: {
          $like: `${this.searchInput}%`,
        },
        $select: this.select,
      };
    },
    params() {
      return {
        query: { $select: this.select },
      };
    },
  },

  created() {
    this.init();
  },
  methods: {
    ...mapActions({
      findScenarios: "scenarios/find",
      fetchScenario: "scenarios/get",
      createScenario: "scenarios/create",
      patchScenario: "scenarios/patch",
    }),
    init() {
      this.findScenarios({ paginate: false });
      if (this.scenario_id != null) {
        this.fetchScenario(this.scenario_id);
      }
    },
    create(name) {
      this.createScenario({ name }).then((scenario) => {
        this.scenario_id = scenario.id;
      });
    },
    rename(name) {
      this.patchScenario([this.scenario_id, { name }]);
    },
  },
};
</script>

<style></style>
